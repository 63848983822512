import { HttpClient } from '@angular/common/http';
import { computed, inject } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';

import { APP_CONFIG, ApplicationConfig, initialApplicationConfig } from '@@shared/providers/application-config-provider/application-config-provider.model';

export const ConfigurationStore = signalStore(
	{ providedIn: 'root' },
	withState(() => ({ ...initialApplicationConfig, ...inject(APP_CONFIG) })),
	withComputed((store) => ({
		ssoMessage: computed(() => store.messages()?.sso),
		isCustomSupportMessageEnabled: computed(() => store.messages()?.support?.content && store.messages()?.support?.title),
	})),
	withMethods((store) => {
		const http = inject(HttpClient);
		const resetState = inject(APP_CONFIG);

		return {
			// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
			reset(): void {
				patchState(store, () => ({ ...initialApplicationConfig, ...resetState }));
			},

			// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
			loadCustomerConfig(customerId: string): void {
				const customerConfigFileName = `assets/config/config-${customerId.toLowerCase()}.json`;

				http.get<ApplicationConfig>(customerConfigFileName)
					.subscribe(config => {
						patchState(store, (state) => ({ ...state, ...(config || {}) }));
					});
			}
		};
	})
);
